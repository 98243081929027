<template>
  <v-container>
    <v-row>
      <v-col cols="6" sm="3" class="pa-0" v-for="(item,i) in videos" :key="i">
        <CardVideo :baseTo="'/depoimento/'" :item="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardVideo from '@/components/press/CardVideo'

export default {
  components: {
    CardVideo
  },
  computed: {
    videos() {
      return this.$store.state.videosBornH2P
    }
  }
}
</script>

<style>
</style>